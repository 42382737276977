@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

.fas {
  width: 100%;
  margin-top: 3%;
  .container-fas {
    box-shadow: inset 0 0 10px 0 black;
    background-image: url("../../asset/main/fas.webp"); /* Replace with your desktop image URL */
    background-size: cover;
    background-position: center;
    height: 70vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    //border-radius: 0px 0px 0px 100px;
    //box-shadow: inset 0px -5px 10px #d5d5d5;
    @media (max-width: 768px) {
      /* Adjust breakpoint as needed */
      height: 50vh;
      background-repeat: no-repeat;
      // border-radius: 0px 0px 0px 30px;
    }
  }
}
