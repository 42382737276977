.card {
  margin-top: 50px;
  width: 100%;
  .container-card {
    width: 100%;
    .cardhome-container {
      margin-top: 25px;
      width: 100%;
      display: grid;
      grid-template-columns: auto auto auto;
      justify-content: center;
      @media (max-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }
    .header {
      margin-top: 10px;
      .judul-card {
        font-size: 2rem;
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        position: relative;
        color: rgb(33, 33, 33);
        letter-spacing: 0.2em;
        @media (max-width: 768px) {
          font-size: 30px;
        }
        span {
          position: relative;
          margin-left: 0.5em;
        }

        .pink {
          width: 2em;
          height: 2em;
          border-radius: 2em;
          background-color: rgb(255, 163, 178);
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
