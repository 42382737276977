.areas {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5%;
  padding-top: 50px;
  .container-areas {
    width: 90%;
    @media (max-width: 768px) {
      width: 98%;
    }
    .cardhome-container {
      margin-top: 25px;
      width: 100%;
      display: grid;
      grid-template-columns: auto auto auto;
      justify-content: center;
      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .container {
      width: 100%;
      .header-areas {
        text-align: center;
        .judul-areas {
          color: #086d44;
          font-weight: 600;
          font-family: "Poppins", sans-serif;
          font-size: 50px;
          @media (max-width: 768px) {
            font-size: 55px;
          }
        }
      }
    }
  }
}
