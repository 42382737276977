.aboutcontainer {
  width: 100%;
  margin-top: 3%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .aboutcenter {
    width: 70%;
    display: flex;

    @media (max-width: 768px) {
      width: 90%;
    }

    .aboutlogo {
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;
      .logo {
        width: 300px;
        @media (max-width: 768px) {
          display: none;
        }
      }
      .logodes {
        margin-left: 22px;
        color: #749a8a;
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
    .logodes {
      display: none;
      width: 0px;
      @media (max-width: 768px) {
        width: 100%;
        display: block;
        color: #1d7e10;
        font-size: 16px;
        font-family: "Roboto", Sans-serif;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
    .logoprice {
      display: none;
      width: 0px;
      @media (max-width: 768px) {
        width: 100%;
        display: block;
        font-size: 24px;
        color: #2b2b2b;
        font-family: "Roboto", Sans-serif;
        font-weight: 600;
        margin-bottom: 20px;
      }
    }
    .about {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      @media (max-width: 768px) {
        width: 100%;
      }
      .buttonwa {
        width: 100%;
        text-align: left;
        @media (max-width: 768px) {
          visibility: hidden;
          width: 0px;
        }
        .whatsapp {
          cursor: pointer;
          padding: 15px 50px;
          border-color: #61ce70;
          background-color: #61ce70;
          color: white;
          border-radius: 10px;
          font-size: 18px;
          &:hover {
            background-color: white;
            color: #61ce70;
          }
          @media (max-width: 768px) {
            visibility: hidden;
            padding: 0px;
          }
        }
      }
    }
    .abouttitle {
      color: #086d44;
      font-family: "Roboto", Sans-serif;
      font-weight: 600;
      font-size: 25px;
      margin-bottom: 25px;
    }
    .aboutsubtitle {
      margin-top: -10px;
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;
      .read-more-button {
        margin-top: 10px;
        cursor: pointer;
      }
    }
  }
  .future {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
    @media (max-width: 768px) {
      width: 98%;
      justify-content: center;
    }
    .judul-futur {
      margin-bottom: 3%;
      color: #086d44;
      font-family: "Roboto", Sans-serif;
      font-weight: 600;
      font-size: 45px;
      margin-bottom: 25px;
      background-color: #086d4542;
      padding: 5px 15px;
      border-radius: 10px;
      @media (max-width: 768px) {
        font-size: 25px;
        text-align: center;
      }
    }
    .image {
      width: 40%;
      @media (max-width: 768px) {
        width: 98%;
      }
      img {
        width: 100%;
        border-radius: 10px;
      }
    }
  }
}
