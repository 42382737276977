.cardrumahdetail {
  width: 100%;
  .cluster-card {
    width: 400px;
    margin-left: 15px;
    margin-top: 10px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 10px 0;
    hr {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 7px;
      width: 90%;
    }
    @media (max-width: 768px) {
      margin-bottom: 20px;
      margin-left: 0px;
      height: auto;
      padding-bottom: 10px;
    }
    .slick-dots {
      bottom: 15px;
      li {
        width: 10px;
      }
    }
    .slick-next {
      right: 25px;
    }
    .slick-next::before,
    .slick-prev::before {
      font-family: "slick";
      font-size: 30px;
    }
    .slick-prev {
      z-index: 1;
      left: 25px;
    }
    .cluster-image {
      width: 100%;
      height: 450px;
      border-radius: 10px 10px 0px 0px;
      object-fit: cover;
    }
    .cluster-card-title {
      font-family: "Roboto", Sans-serif;
      display: flex;
      width: 100%;
      flex-direction: row;
      padding: 35px 0 5px 0;
      .cluster-name {
        text-align: center;
        justify-content: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;

        .cluster-name-sub {
          font-size: 30px;
          font-weight: 600;
          color: #086d44;
          font-family: "Poppins", sans-serif;
        }
        .cluster-name-sub2 {
          width: 80%;
        }
      }
      .cluster-price {
        padding-bottom: 10px;
        justify-content: center;
        flex-direction: column;
        display: flex;
        align-items: center;
        background-color: #086d44;
        border-radius: 10px 0px 0px 10px;
        text-align: center;
        color: #ffffff;
        width: 100%;
        .cluster-price-sub {
          font-size: 50px;
          font-weight: 600;
        }
        //.cluster-price-sub2 {
        //}
      }
    }
    .card-separator {
      height: 0.1px;
      margin-top: 15px;
    }
    .gridspek {
      background-color: #086d4539;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      color: #333333;
      padding: 15px 0px;
      @media (max-width: 768px) {
        padding: 15px 0px;
      }
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 5px;
        padding-right: 10px;
        @media (max-width: 768px) {
          padding-right: 10px;
          font-size: 14px;
        }
      }
    }
    .cluster-button {
      display: flex;
      justify-content: center;
      padding-top: 15px;
      padding-bottom: 10px;

      .cluster-whatsapp {
        display: inline-block;
        padding: 10px 100px;
        cursor: pointer;
        border: none;
        border-radius: 5px;
        background-color: #086d44; /* WhatsApp green */
        color: white;
        font-size: 16px;
        border: solid;
        border-color: #086d44;
        &:hover {
          background-color: white;
          color: black;
          transition: 0.3s;
        }
      }
    }
  }
}
