@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

.hero {
  width: 100%;
  .container-hero {
    background-image: url("../../asset/main/herod.webp"); /* Replace with your desktop image URL */
    background-size: cover;
    background-position: center;
    height: 80vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    //border-radius: 0px 0px 0px 100px;
    //box-shadow: inset 0px -5px 10px #d5d5d5;
    @media (max-width: 768px) {
      /* Adjust breakpoint as needed */
      background-image: url("../../asset/main/herom.webp"); /* Replace with your mobile image URL */
      height: 80vh;
      background-repeat: no-repeat;
      // border-radius: 0px 0px 0px 30px;
    }
    .header-hero {
      width: 100%;
      .judul-hero {
        font-family: "Roboto Condensed";
        color: white;
        font-size: 40px;
        font-weight: 600;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.497);
      }
      .subjudul-hero {
        font-family: "Roboto Condensed";
        color: white;
        font-size: 45px;
        font-weight: 600;
        line-height: 1;
        letter-spacing: 6.5px;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.497);
        @media (max-width: 768px) {
          letter-spacing: 0px;
        }
      }
    }
  }
}
