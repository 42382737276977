.produk {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5%;
  padding-top: 50px;
  .container-produk {
    width: 100%;
    @media (max-width: 768px) {
      width: 98%;
    }
    .container {
      width: 100%;
      .header-produk {
        text-align: center;
        .judul-produk {
          color: #086d44;
          font-weight: 600;
          font-family: "Poppins", sans-serif;
          font-size: 65px;
          @media (max-width: 768px) {
            font-size: 55px;
          }
        }
      }
    }
  }
}
