@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

.lokasi {
  margin-top: 5%;
  margin-bottom: 3%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .container-lokasi {
    width: 80%;
    @media (max-width: 768px) {
      width: 95%;
    }
    .header-lokasi {
      font-family: "Roboto Condensed";
      font-size: 50px;
      font-weight: 600;
      color: #086d44;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .container-content {
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      .carousellokasi {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
          width: 98%;
        }
        .slick-slider {
          width: 95%;
          //margin: 5px;
          @media (max-width: 768px) {
            width: 98%;
          }
          .kartugambar {
            width: 100%;
            object-fit: cover;
          }
          .slick-next::before,
          .slick-prev::before {
            font-family: "slick";
            font-size: 30px;
          }

          .slick-dots {
            position: absolute; /* Make dots absolute for positioning within slides */
            bottom: 10px; /* Adjust vertical positioning */
            left: 50%; /* Center dots horizontally */
            transform: translateX(-50%); /* Center dots precisely */
            z-index: 1; /* Ensure dots are above image content */
          }
          .slick-next {
            right: 25px;
          }
          .slick-prev {
            z-index: 1;
            left: 25px;
          }
          .slick-dots {
            li {
              margin: 0;
            }
          }
        }
      }
      .mapslokasi {
        width: 50%;
        @media (max-width: 768px) {
          width: 98%;
        }
        .maps-lokasi {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          @media (max-width: 768px) {
            flex-direction: column;
            overflow: hidden;
          }
          .img-lokasi-dekstop {
            width: 100%;
            height: 58vh;
            object-fit: cover;
            @media (max-width: 768px) {
              visibility: hidden;
              width: 0%;
              height: 0%;
            }
          }
          .img-lokasi-mobile {
            visibility: hidden;
            width: 0%;
            @media (max-width: 768px) {
              visibility: visible;
              width: 98%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
